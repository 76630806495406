import * as React from 'react';

import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { RegularButton } from 'components';
import { tokens } from 'context/theme.context';

type ConfirmationDialogProps = {
  message: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  open: boolean;
  setOpen: (openDialog: boolean) => void;
  onConfirm: () => void;
  loading?: boolean;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  message,
  setOpen,
  open,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  loading,
}) => {
  const colors = tokens();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <Box p="25px" width="400px">
          <DialogContent>
            <DialogContentText
              sx={{
                fontSize: '15px',
              }}
            >
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <RegularButton
              onClick={handleClose}
              label={cancelButtonLabel ?? 'Cancel'}
              variant="outlined"
              styles={{
                backgroundColor: 'none',
                borderColor: colors.text,
                color: colors.text,
              }}
            />
            <RegularButton
              label={confirmButtonLabel ?? 'Yes'}
              loading={loading}
              onClick={() => {
                setOpen(false);
                onConfirm();
              }}
              styles={{
                backgroundColor: colors.redAccent,
              }}
              color="error"
            />
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
