/* eslint-disable react-hooks/exhaustive-deps */

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { tokens } from 'context/theme.context';

export type DateRangeValue = [string | undefined, string | undefined];

type CustomDateRangeFilterProps = {
  setFilterDateRange: Dispatch<SetStateAction<DateRangeValue>>;
  filterDateRange: DateRangeValue;
  hideQuickFilter?: boolean;
};

const CustomDateRangeFilter: React.FC<CustomDateRangeFilterProps> = ({
  setFilterDateRange,
  filterDateRange,
  hideQuickFilter,
}) => {
  const colors = tokens();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    const start_date = startDate?.format('YYYY-MM-DD');
    const end_date = endDate?.format('YYYY-MM-DD');
    setFilterDateRange([start_date, end_date]);
  }, [startDate, endDate]);

  useEffect(() => {
    if (filterDateRange) {
      setStartDate(dayjs(filterDateRange[0]));
      setEndDate(dayjs(filterDateRange[1]));
    }
  }, [filterDateRange[0], filterDateRange[1]]);

  return (
    <Box display="flex" gap="10px" alignItems={'center'}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          maxDate={endDate}
          slotProps={{
            textField: {
              variant: 'filled',
              size: 'small',
            },
          }}
          sx={{
            '.MuiFormLabel-root.Mui-focused': { color: colors.light },
            '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none', width: '140px' },
            '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
            '.MuiFilledInput-root:before': { border: 'none' },
            '.Mui-disabled:before': { border: 'none !important' },
            '.MuiFilledInput-root:after': {
              borderBottom: `1px solid ${colors.primary}`,
              borderRadius: 2,
              // height: '100%',
            },
            '.MuiFilledInput-input': { height: '17px' },
            '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
          }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          minDate={startDate}
          slotProps={{
            textField: {
              variant: 'filled',
              size: 'small',
            },
          }}
          sx={{
            '.MuiFormLabel-root.Mui-focused': { color: colors.light },
            '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none', width: '140px' },
            '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
            '.MuiFilledInput-root:before': { border: 'none' },
            '.Mui-disabled:before': { border: 'none !important' },
            '.MuiFilledInput-root:after': {
              borderBottom: `1px solid ${colors.primary}`,
              borderRadius: 2,
              height: '100%',
            },
            '.MuiFilledInput-input': { height: '17px' },
            '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CustomDateRangeFilter;
