import { ApiQuery } from 'model/interface';
import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const path = 'order';

export const getOrderByOrderName = (order_name: string) => {
  return axios.get(`${baseURL}/${path}/order/name`, { params: { order_name } });
};

export const getOrder = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getOrders = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getSkuLocations = (batch_id: number) => {
  return axios.get(`${baseURL}/${path}/sku-locations/${batch_id}`);
};

export const getOrdersByBatch = (batch_id: number) => {
  return axios.get(`${baseURL}/${path}/batch/${batch_id}`);
};

export const getShippingSummary = () => {
  return axios.get(`${baseURL}/${path}/shipping/summary`);
};

export const removeFromBatch = (order_id: number) => {
  return axios.patch(`${baseURL}/${path}/remove-from-batch/${order_id}`);
};

export const syncDear = () => {
  return axios.post(`${baseURL}/${path}/sync-dear`);
};

export const processShipment = (company: string, orders: string[], pickup_date: string) => {
  return axios.post(
    `${baseURL}/${path}/process-shipment`,
    { orders, company, pickup_date },
    { responseType: 'arraybuffer' }
  );
};

export const addPickedCount = (line_item_id: number) => {
  return axios.patch(`${baseURL}/${path}/line-item/pick/${line_item_id}`);
};

export const stopCheck = (order_id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/stop-check/${order_id}`, data);
};

export const resolveStopCheck = (order_id: number) => {
  return axios.patch(`${baseURL}/${path}/stop-check/resolve/${order_id}`);
};

export const markAsPacked = (order_id: number) => {
  return axios.post(`${baseURL}/${path}/mark-as-packed/${order_id}`);
};

export const markAsShipped = (orders: any[]) => {
  return axios.post(`${baseURL}/${path}/mark-as-shipped`, { orders });
};

export const printLabel = (orders: string[]) => {
  return axios.post(`${baseURL}/${path}/print-label`, { orders }, { responseType: 'arraybuffer' });
};

export const deleteOrder = (orders: string[]) => {
  return axios.delete(`${baseURL}/${path}`, { params: { orders } });
};
