import { Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';

import TabPanel from './CustomTabPanels';
import { tokens } from 'context/theme.context';
import { useSearchParams } from 'react-router-dom';

export type TabProps = {
  icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  label: string;
  content?: JSX.Element | any;
};

type CustomTabsProps = {
  tabs: TabProps[];
  selected?: number;
  setSelected?: (newValue: number) => void;
  tabBackgroundColor?: any;
  tabMinWidth?: string;
  tabGroupId?: string;
};

const CustomTabs: React.FC<CustomTabsProps> = ({
  tabs,
  selected = 0,
  setSelected,
  tabBackgroundColor,
  tabMinWidth = '200px',
  tabGroupId = 'tab',
}) => {
  const colors = tokens();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlTab = searchParams.get(tabGroupId);
  const [value, setValue] = useState<number>(urlTab ? parseInt(urlTab) : selected);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSelected && setSelected(newValue);
  };

  useEffect(() => {
    if (tabs.length && value >= tabs.length) setValue(0);
  }, [value, tabs]);

  useEffect(() => {
    if (!firstLoad) {
      setSearchParams((searchParams) => {
        searchParams.set(tabGroupId, value.toString());
        return searchParams;
      });
    }
    setFirstLoad(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {tabs.length > 1 && (
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          hidden={tabs.length === 1}
          sx={{
            marginBottom: '20px',
            backgroundColor: tabBackgroundColor ?? colors.secondary_background,
            borderRadius: '8px',
            '& .MuiButtonBase-root.MuiTab-root': {
              minHeight: '60px',
              minWidth: tabMinWidth,
              fontSize: '16px',
              textTransform: 'none',
              justifyContent: 'left',
              fontWeight: 'bold',
            },
            '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
              color: colors.accent,
            },
            '& .MuiButtonBase-root.MuiTab-root:not(:last-child)::before': {
              content: `""`,
              display: 'block',
              position: 'absolute',
              right: '0',
              height: '50%',
              top: '25%',
              borderRight: '2px solid ' + colors.light,
            },
            '& .MuiTabs-indicator': {
              backgroundColor: colors.accent,
            },
            '& .MuiTabScrollButton-root': {
              backgroundColor: colors.primary,
            },
            '& .MuiTabScrollButton-root.Mui-disabled': {
              opacity: 0.5,
              color: colors.primary,
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} icon={tab.icon} label={tab.label} iconPosition="start" />
          ))}
        </Tabs>
      )}
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
};

export default CustomTabs;
