import 'styles/Components.css';

import { IconButton, InputAdornment, TextField } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { tokens } from 'context/theme.context';
import { useState } from 'react';

type CustomPasswordFieldProps = {
  label: string;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: unknown;
  fieldName: string;
  disabled?: boolean;
  touched?: boolean;
  error?: any;
  span?: number;
  required?: boolean;
  size?: 'small' | 'medium' | undefined;
  optional?: boolean;
};

const CustomPasswordField: React.FC<CustomPasswordFieldProps> = ({
  label,
  handleBlur,
  handleChange,
  value,
  fieldName,
  disabled,
  touched,
  error,
  span,
  required,
  size = 'small',
  optional,
}) => {
  const colors = tokens();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      fullWidth
      variant="filled"
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={label + (optional ? ' (optional)' : '')}
      disabled={disabled}
      onBlur={handleChange}
      onChange={handleChange}
      value={value}
      name={fieldName}
      error={!!touched && !!error}
      helperText={touched && error}
      required={required}
      size={size}
      sx={{
        '.MuiFormLabel-root.Mui-focused': { color: colors.light },
        '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none' },
        '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
        '.MuiFilledInput-root:before': { border: 'none' },
        '.Mui-disabled:before': { border: 'none !important' },
        '.MuiFilledInput-root:after': { borderBottom: `1px solid ${colors.primary}`, borderRadius: 2, height: '100%' },
        '.MuiFilledInput-input': { height: '24px' },
        '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
        gridColumn: span ? 'span ' + span : undefined,
      }}
    />
  );
};

export default CustomPasswordField;
