import { Badge, Box, Popover, SxProps, Theme, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';

import CustomCheckbox from './fields/CustomCheckbox';
import FilterListIcon from '@mui/icons-material/FilterList';
import RegularButton from './buttons/RegularButton';

interface Props {
  optionList: CheckboxOptionProps[];
  buttonLabel?: string;
  setSelected?: Dispatch<SetStateAction<string[]>>;
  selected?: string[];
  buttonStyle?: SxProps<Theme>;
}

export type CheckboxOptionProps = {
  label: string;
  id: string;
  data?: any;
};

const CustomFilter: React.FC<Props> = ({ optionList, buttonLabel = 'Filters', setSelected, selected, buttonStyle }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openFilterPopover = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setSelected) {
      if (event.target.checked) {
        setSelected((prev) => [...prev, event.target.name]);
      } else {
        setSelected((prev) => prev.filter((item) => item !== event.target.name));
      }
    }
  };

  return (
    <>
      <Badge badgeContent={selected?.length} color="secondary">
        <RegularButton
          aria-describedby={'filter-popover'}
          startIcon={<FilterListIcon />}
          color="primary"
          onClick={handleClick}
          label={buttonLabel}
          styles={buttonStyle}
        />
      </Badge>

      <Popover
        id={'filter-popover'}
        open={openFilterPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ '& .MuiPaper-root': { borderRadius: '15px' } }}
      >
        <Box padding={'10px'} display={'flex'} alignContent={'left'} flexDirection={'column'} gap="5px">
          <Typography fontSize="14px" fontWeight="bold" paddingLeft="10px">
            {buttonLabel}
          </Typography>
          {optionList.map((option) => {
            return (
              <CustomCheckbox
                key={option.id}
                style={{
                  padding: '8px',
                  backgroundColor: '#fffafa',
                  borderRadius: '10px',
                }}
                label={option.label}
                handleChange={handleFilterChange}
                value={selected?.includes(option.id)}
                fieldName={option.id}
              />
            );
          })}
        </Box>
      </Popover>
    </>
  );
};

export default CustomFilter;
