import { IconButton, SxProps, Theme, Tooltip } from '@mui/material';

import { ReactNode } from 'react';

type IconButtonProps = {
  tooltip?: string;
  placement?: any;
  icon: ReactNode;
  onClick: () => void;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | null;
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps<Theme>;
};

const CustomIconButton: React.FC<IconButtonProps> = ({
  tooltip,
  icon,
  placement,
  onClick,
  color = 'secondary',
  size = 'small',
  sx,
}) => {
  return (
    <Tooltip title={tooltip} placement={placement ? placement : 'top'} arrow>
      <IconButton onClick={onClick} color={color === null ? undefined : color} size={size} sx={sx && sx}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default CustomIconButton;
