import { ApiQuery } from 'model/interface';
import { BatchModel } from 'model/Entities';
import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const path = 'batch';

export const getBatch = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getBatches = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const createBatch = (data: any, batch_name: string) => {
  return axios.post(`${baseURL}/${path}`, { orders: data, batch_name });
};

export const updateBatch = (batch_id: number, data: BatchModel) => {
  return axios.put(`${baseURL}/${path}/${batch_id}`, data);
};

export const validateOrders = (orders: string[]) => {
  return axios.post(`${baseURL}/${path}/validate-orders`, { orders });
};

export const resolveOrders = (orders: any) => {
  return axios.post(`${baseURL}/${path}/resolve-orders`, { orders });
};
