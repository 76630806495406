import { DataGrid, GridColDef, GridRowParams, GridSelectionModel, GridSortDirection } from '@mui/x-data-grid';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ApiQuery } from 'model/interface';
/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import SearchBar from './SearchBar';
import { tokens } from 'context/theme.context';

type CustomTableProps = {
  rows: any[];
  rowCount: number;
  columns: GridColDef[];
  checkboxSelection?: boolean;
  loading?: boolean;
  rowId?: string;
  getData: (query: ApiQuery, others?: any) => void;
  isRowSelectable?: boolean;
  customSelectableRow?: (params: GridRowParams) => boolean;
  handleSelectRow?: (selectionModel: GridSelectionModel) => void;
  autoSelectedRows?: GridSelectionModel;
  hideSearch?: boolean;
  searchKeys?: string;
  disableSelectionOnClick?: boolean;
  keepNonExistentRowsSelected?: boolean;
  headerComponent?: React.ReactNode;
  filterComponent?: React.ReactNode;
  initialPage?: number;
  initialPageSize?: number;
  initialOrder?: GridSortDirection;
  initialOrderBy?: string;
  otherFilters?: any;
  hideFooterPagination?: boolean;
};

const CustomTable = forwardRef((props: CustomTableProps, ref) => {
  const {
    rows,
    rowCount,
    columns,
    checkboxSelection,
    loading,
    rowId = 'id',
    getData,
    isRowSelectable,
    customSelectableRow,
    handleSelectRow,
    autoSelectedRows,
    hideSearch,
    searchKeys,
    disableSelectionOnClick,
    keepNonExistentRowsSelected,
    headerComponent,
    filterComponent,
    initialPage,
    initialPageSize,
    initialOrder,
    initialOrderBy,
    otherFilters,
    hideFooterPagination,
  } = props;

  useImperativeHandle(ref, () => ({ refreshData, clearSelected }));
  const savedPageSize = localStorage.getItem('pageSize');
  const defaultPageSize = savedPageSize ? Number(savedPageSize) : 200;
  const colors = tokens();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [firstLoad, setFirsLoad] = useState(true);
  const [page, setPage] = useState<number>(initialPage ?? 0);
  const [pageSize, setPageSize] = useState<number>(100);
  const [order, setOrder] = useState<GridSortDirection>(initialOrder ?? 'asc');
  const [orderBy, setOrderBy] = useState<string>(initialOrderBy ?? '');
  const [search, setSearch] = useState<string>('');
  const prevSearchKeyRef = useRef('');
  const orderChanged: any = useRef();

  useEffect(() => {
    if (autoSelectedRows && autoSelectedRows.length) {
      setSelectionModel(autoSelectedRows);
      // handleClickRow(autoSelectedRows);
    } else {
      setSelectionModel([]);
    }
  }, [autoSelectedRows]);

  //save pageSize in localStorage if it changes
  useEffect(() => {
    localStorage.setItem('pageSize', String(pageSize));
  }, [pageSize]);

  const handleClickRow = (newSelectionModel: GridSelectionModel) => {
    if (JSON.stringify(newSelectionModel) === JSON.stringify(selectionModel)) {
      setSelectionModel([]);
      handleSelectRow && handleSelectRow([]);
    } else {
      setSelectionModel(newSelectionModel);
      handleSelectRow && handleSelectRow(newSelectionModel);
    }
  };

  const updatePageOrRefresh = () => {
    if (page === 0) refreshData();
    else setPage(0);
    prevSearchKeyRef.current = search;
  };

  const refreshData = () => {
    getData({
      page: page + 1,
      length: pageSize,
      order_by: orderBy,
      order: order,
      search: search,
      ...otherFilters,
    });
  };

  const clearSelected = () => {
    setSelectionModel([]);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updatePageOrRefresh();
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [pageSize, search, order, orderBy, otherFilters]);

  useEffect(() => {
    if (!firstLoad) refreshData();
    setFirsLoad(false);
  }, [page]);

  return (
    <Box sx={{ width: '100%' }}>
      {filterComponent && headerComponent && <Box marginBottom="20px">{headerComponent}</Box>}
      <Box
        display="grid"
        gridTemplateColumns={hideSearch ? '1fr' : '1fr 25%'}
        columnGap="20px"
        alignItems="flex-end"
        sx={{ mb: '20px' }}
      >
        <Box>{filterComponent ?? headerComponent}</Box>
        {!hideSearch && <SearchBar handleSearch={(searchKey) => setSearch(searchKey)} searchKeys={searchKeys} />}
      </Box>

      <Box
        // minHeight={getTableHeight()}
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-row': {
            fontSize: 14,
          },
          '& .emphasis': {
            color: colors.accent,
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '2px solid ' + colors.dark,
            fontSize: 14,
          },
          '& .MuiDataGrid-menuIcon': {
            display: 'none !important',
          },
          '& .MuiDataGrid-virtualScroller': {
            // backgroundColor: colors.primary,
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
          },
          '& .MuiCheckbox-root': {
            color: `${colors.secondary} !important`,
            padding: '1px',
          },
          '& .MuiCheckbox-root.Mui-disabled': {
            color: `${colors.dark} !important`,
            padding: '1px',
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.text} !important`,
          },
          '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
            backgroundColor: '#fffafa',
          },
          '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: '#fffafa',
          },
          '& .MuiCircularProgress-root': {
            color: colors.dark,
          },
          '& .MuiDataGrid-cell': {
            paddingBlock: '5px !important',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
          '& .MuiDataGrid-cellContent': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '1rem',
          },
          '& .MuiDataGrid-columnSeparator': {
            color: `${colors.dark} !important`,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 13,
            fontWeight: 'bold',
          },
        }}
      >
        <DataGrid
          rows={rows}
          rowBuffer={10}
          rowCount={rowCount}
          loading={loading}
          rowsPerPageOptions={[100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          sortModel={[{ field: orderBy, sort: order }]}
          checkboxSelection={checkboxSelection}
          isRowSelectable={customSelectableRow ? customSelectableRow : () => !!isRowSelectable || !!checkboxSelection}
          onSelectionModelChange={checkboxSelection ? handleClickRow : undefined}
          selectionModel={selectionModel}
          columns={columns}
          density="compact"
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          onSortModelChange={(model) => {
            if (model.length) {
              setOrder(model[0].sort);
              setOrderBy(model[0].field);
              orderChanged.current = true;
            } else if (orderChanged.current) {
              setOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
            }
          }}
          getRowId={(row: any) => row[rowId]}
          getRowHeight={() => 'auto'}
          autoHeight
          disableSelectionOnClick={disableSelectionOnClick}
          keepNonExistentRowsSelected={keepNonExistentRowsSelected}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          hideFooterPagination={hideFooterPagination}
          onCellClick={(params) => {
            if (params.field !== 'action' && isRowSelectable && !checkboxSelection) {
              handleClickRow([params.id]);
            }
          }}
        />
      </Box>
    </Box>
  );
});

export default CustomTable;
