import { AutoFocusTextField, CustomModal, CustomSwitch, Header, RegularButton } from 'components';
import { Box, Typography } from '@mui/material';
import { LineItemModel, OrderModel, STATION } from 'model/Entities';
import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getOrderByOrderName, markAsPacked } from 'api/order';

import Barcode from 'react-barcode';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { GIFTBOX_SKU } from 'screens/Sorting';
import { Howl } from 'howler';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import { formatDateTime } from 'utils';
import { tokens } from 'context/theme.context';

const ScanningStation = () => {
  const colors = tokens();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [order, setOrder] = useState<OrderModel>();
  const [orderNotFound, setOrderNotFound] = useState(false);
  const [orderName, setOrderName] = useState<string>('');
  const [sku, setSku] = useState<string>('');
  const [scannedItems, setScannedItems] = useState<LineItemModel[]>([]);
  const [message, setMessage] = useState<ReactNode>();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState<ReactNode>();
  const [manualMode, setManualMode] = useState(false);

  const orderRef = useRef<HTMLInputElement | null>();

  const giftBoxCount: number = useMemo(() => {
    if (!order) return 0;
    return order.line_items.reduce((total, item) => total + (item.sku === GIFTBOX_SKU ? item.quantity : 0), 0);
  }, [order]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Scanning Station' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scanOrder = async (value: string) => {
    setOrderNotFound(false);
    if (value) {
      try {
        const { data } = await getOrderByOrderName(value);
        if (data.status === 'error') data.orders = [];
        setOrder(data);
        setOrderNotFound(false);
      } catch (error) {
        console.error(error);
        setOrder(undefined);
        setOrderNotFound(true);
      }
      setScannedItems([]);
      setValidationMessage(undefined);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!manualMode) scanSku(sku);
    }, 100);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      scanOrder(orderName);
    }, 1000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderName]);

  const scanSku = async (value: string) => {
    if (value && order) {
      setValidationMessage(undefined);
      const found = order.line_items.find((item) => item.sku === value);
      if (found) {
        if (found.quantity === found.scanned) {
          setMessage(
            <Typography fontSize="20px" fontWeight="bold" color={colors.redAccent}>
              SKU {value} is more than what is needed in this order.
            </Typography>
          );
          setOpenErrorModal(true);
          playSound();
          return;
        }
        setScannedItems((prev) => {
          const existing = prev.find((item) => item.sku === value);
          if (existing) {
            existing.scanned = existing.scanned + 1;
            return [...prev];
          }
          found.scanned = 1;
          return [...prev, found];
        });
        setSku('');
      } else {
        setMessage(
          <Typography fontSize="20px" fontWeight="bold" color={colors.redAccent}>
            SKU {value} is not part of this order.
          </Typography>
        );
        setOpenErrorModal(true);
        playSound();
      }
    }
  };

  const playSound = () => {
    const sound = new Howl({
      src: ['/error-sound.mp3'],
    });
    sound.play();
  };

  const closeModal = () => {
    setMessage(undefined);
    if (!manualMode) setSku('');
  };

  const handleKeyPress: any = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && manualMode) {
      scanSku(sku);
    }
  };

  const verifyOrder = async () => {
    if (order) {
      const missingItems: LineItemModel[] = [];

      order.line_items.forEach((item) => {
        const found = scannedItems.find((scannedItem) => scannedItem.id === item.id);
        const validLineItem = !!found && found.scanned === found.quantity;
        if (!validLineItem) {
          item.missing_count = item.quantity - (item.scanned ?? 0);
          missingItems.push(item);
        }
      });

      const isValid = !missingItems.length;

      setValidationMessage(undefined);
      if (!isValid) {
        playSound();
        setValidationMessage(
          <Box mt="50px">
            <Typography fontSize="40px" fontWeight="bold" color={colors.redAccent}>
              Order is invalid. Please review the SKUs scanned.
            </Typography>
            <Typography fontSize="16px">The following line items are missing:</Typography>
            {missingItems.map((item) => (
              <Typography fontSize="20px" ml="20px">
                {item.sku} - {item.missing_count} missing item{item.missing_count > 1 ? 's' : ''}
              </Typography>
            ))}
          </Box>
        );
      } else {
        setLoading(true);
        try {
          const res = await markAsPacked(order.id!);
          if (res.data && !res.data.response) {
            setValidationMessage(
              <Box mt="50px">
                <Typography fontSize="40px" fontWeight="bold" color={colors.greenAccent}>
                  Order is valid. Please continue with the next order.
                </Typography>
                <RegularButton
                  label="Scan New Order"
                  onClick={() => {
                    setOrderName('');
                    setOrder(undefined);
                    setValidationMessage(undefined);
                    orderRef.current?.focus();
                  }}
                />
              </Box>
            );
          } else {
            setValidationMessage(
              <Box mt="50px">
                <Typography fontSize="40px" fontWeight="bold" color={colors.redAccent}>
                  Order was not successfully verified
                </Typography>
                <Typography fontSize="20px" color={colors.redAccent}>
                  {res.data.response?.map((error: any) => error.Exception).join(' ')}
                </Typography>
              </Box>
            );
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <Box>
      <Header title="Scanning Station" />

      <Box mt="50px" display="flex" gap="10px" width="100%" alignItems="flex-start">
        <Box flex="1">
          <Box display="flex" alignItems="center" gap="10px">
            <Typography>Scan Order No.: </Typography>
            <AutoFocusTextField
              value={orderName}
              setValue={setOrderName}
              placeholder="Order No."
              componentRef={orderRef}
            />
          </Box>

          {order && (
            <>
              {!order?.date_scanned && order.current_station === STATION.SCANNING && (
                <Box mt="50px">
                  <Typography fontWeight="bold" fontSize="24px" mb="10px">
                    Verification for Order {order?.order_name}
                  </Typography>

                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box display="flex" alignItems="center" gap="10px">
                      <Typography>Scan SKU: </Typography>
                      <AutoFocusTextField
                        value={sku}
                        setValue={setSku}
                        placeholder="SKU"
                        handleKeyPress={handleKeyPress}
                      />
                    </Box>

                    <Box display="flex" gap="20px">
                      <CustomSwitch
                        value={manualMode}
                        label1=""
                        label2={manualMode ? 'Manual' : 'Auto'}
                        handleChange={(e, checked) => setManualMode(checked)}
                      />

                      <RegularButton
                        label="Scan"
                        onClick={() => scanSku(sku)}
                        styles={{ width: '115px', visibility: manualMode ? undefined : 'hidden' }}
                      />
                    </Box>
                  </Box>

                  {/* IMINGIFTBOX-SAVETHEWORLD */}
                  {giftBoxCount > 0 && (
                    <Box mt="40px">
                      <Typography fontWeight="bold">You need a gift box for this order.</Typography>
                      <Barcode value={GIFTBOX_SKU} height={70} fontSize={16} />
                    </Box>
                  )}
                </Box>
              )}
              {order && (
                <>
                  {!!order.date_scanned ? (
                    <Box mt="50px">
                      <Typography fontWeight="bold" fontSize="24px" mb="10px">
                        Order {order.order_name} has already been verified.
                      </Typography>
                      <Typography>Date and Time Verified: {formatDateTime(order.date_scanned)}</Typography>
                    </Box>
                  ) : (
                    order.current_station !== STATION.SCANNING && (
                      <Box mt="50px">
                        <Typography fontWeight="bold" fontSize="24px" mb="10px" color={colors.redAccent}>
                          Order {order.order_name} has not yet been sorted. Please check Batch #{order.batch_id}
                        </Typography>
                      </Box>
                    )
                  )}
                </>
              )}
            </>
          )}

          {orderNotFound && (
            <Box mt="50px">
              <Typography fontSize="40px" color={colors.accent} alignItems="center" display="flex" gap="10px">
                <SentimentDissatisfiedOutlinedIcon sx={{ fontSize: '40px' }} /> Order Not Found
              </Typography>
            </Box>
          )}
        </Box>
        {order && order.current_station === STATION.SCANNING && (
          <Box flex="1" display="flex" flexDirection="column" gap="40px" justifyContent="center" alignItems="center">
            <Box width="100%">
              <Box
                display="flex"
                gap="5px"
                width="100%"
                padding="15px 20px"
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: '10px',
                  textAlign: 'center',
                  '& p': {
                    fontWeight: 'bold',
                    fontSize: '16px',
                  },
                }}
              >
                <Typography flex="1">SKU</Typography>
                <Typography flex="1">Qty Scanned</Typography>
              </Box>
              {scannedItems.map((line_item, index) => (
                <Box
                  key={line_item.id}
                  display="flex"
                  gap="5px"
                  width="100%"
                  padding="10px 20px"
                  sx={{
                    textAlign: 'center',
                    borderBottom: index === order.line_items.length - 1 ? undefined : '1px solid ' + colors.border,
                    borderRadius: '10px',
                  }}
                >
                  <Typography flex="1">{line_item.sku}</Typography>
                  <Typography flex="1">{line_item.scanned}</Typography>
                </Box>
              ))}
              {!scannedItems.length && (
                <Typography textAlign="center" mt="20px">
                  Start Scanning
                </Typography>
              )}
            </Box>

            <RegularButton
              disabled={!scannedItems.length}
              label="Verify Order"
              startIcon={<CheckCircleOutlinedIcon />}
              onClick={verifyOrder}
              loading={loading}
            />

            {validationMessage}
          </Box>
        )}
      </Box>

      <CustomModal header="Error" open={openErrorModal} setOpen={setOpenErrorModal} placeAtTop onClose={closeModal}>
        {message}
        <Box display="flex" gap="10px" justifyContent="center" mt="20px">
          <RegularButton
            label="Okay"
            onClick={() => {
              setOpenErrorModal(false);
              closeModal();
            }}
            variant="outlined"
            color="info"
            styles={{ width: '150px' }}
          />
        </Box>
      </CustomModal>
    </Box>
  );
};

export default ScanningStation;
