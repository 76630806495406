import { BatchModel, OrderModel } from 'model/Entities';
import { Box, Chip, Typography } from '@mui/material';
import { ConfirmationDialog, CustomForm, CustomModal, CustomTable, Header, RegularButton } from 'components';
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { capitalizeWord, formatDate, formatDateTime, tranformFormErrors } from 'utils';
import { getBatches, updateBatch } from 'api/batch';
import { getOrders, getSkuLocations, printLabel, processShipment, removeFromBatch } from 'api/order';

import { ApiQuery } from 'model/interface';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CombinePickList from './CombinePickList';
import CommercialInvoice from './CommercialInvoice';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import OrderValidation from 'screens/Orders/OrderValidation';
import PickList from './PickList';
import { SHIPPING_COMPANY } from 'utils/constant';
import { UserContext } from 'context/user.context';
import moment from 'moment';
import { process_shipment_schema } from 'model/schema';
import { tokens } from 'context/theme.context';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';

const Batches = () => {
  const colors = tokens();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState<BatchModel>();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [openViewOrders, setOpenViewOrders] = useState(false);
  const [openResolveOrders, setOpenResolveOrders] = useState(false);
  const [openSummarizedErrors, setOpenSummarizedErrors] = useState(false);
  const [summarizedErrors, setSummarizedErrors] = useState<any[]>([]);

  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [selectedOrdersDetails, setSelectedOrdersDetails] = useState<OrderModel[]>([]);
  const [orderCount, setOrderCount] = useState<number>(0);

  const [selectedOrder, setSelectedOrder] = useState<OrderModel>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openProcessShipment, setOpenProcessShipment] = useState(false);
  const [processShipmentLoading, setProcessShipmentLoading] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState<string>();
  const [skuLocations, setSkuLocations] = useState<any>({});
  const [skuLocationsLoading, setSkuLocationsLoading] = useState(false);
  const [documentToPrint, setDocumentToPrint] = useState<string>();
  const [loadingPrint, setLoadingPrint] = useState(false);

  const batchTableRef: any = useRef();
  const orderTableRef: any = useRef();
  const printableRef = useRef(null);
  const printableCombineRef = useRef(null);
  const printableCommercialInvoice = useRef(null);

  const handlePrintPickList = useReactToPrint({
    content: () => printableRef.current,
  });

  const handlePrintCombinePickList = useReactToPrint({
    content: () => printableCombineRef.current,
  });

  const handlePrintCommercialInvoice = useReactToPrint({
    content: () => printableCommercialInvoice.current,
  });

  const processOrderShipment = (company: string, pickup_date: Date) => {
    setProcessShipmentLoading(true);
    const date = moment(pickup_date).format('YYYY-MM-DD');
    setOpenProcessShipment(false);
    processShipment(company, selectedOrders, date)
      .then((res) => {
        if (res.data && !res.data.error && !res.data.code) {
          downloadPdf(res.data, company);
          enqueueSnackbar(`Order successfully processed!`, { variant: 'success' });
        } else {
          enqueueSnackbar(`Error in processing shipment!`, { variant: 'error' });
        }
        orderTableRef.current.refreshData();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(`Order was not successfully processed!`, { variant: 'error' });
      })

      .finally(() => setProcessShipmentLoading(false));
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Batches' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedBatch?.id) {
      setSkuLocationsLoading(true);
      getSkuLocations(selectedBatch.id)
        .then((res) => setSkuLocations(res.data))
        .finally(() => setSkuLocationsLoading(false));
    }
  }, [selectedBatch?.id]);

  useEffect(() => {
    if (selectedBatch && documentToPrint) {
      document.title = `Batch-SN-${selectedBatch.id}-${documentToPrint}`;
    } else {
      document.title = 'WUDI';
    }
  }, [selectedBatch, documentToPrint]);

  const getSortingStatusLabel = (value: string) => {
    switch (value) {
      case 'open':
      case 'error':
        return 'Not Started';

      case 'complete':
        return 'Completed';

      default:
        return capitalizeWord(value);
    }
  };

  const getShippingStatusLabel = (orders: OrderModel[], created_at: string) => {
    const totalOrders = orders.length;
    const shippedCount = orders.filter((order) => order.shipping_status === 'shipped').length;
    const now = new Date();
    const diffInMs = now.getTime() - new Date(created_at).getTime();
    const hoursInMs = 72 * 60 * 60 * 1000;
    const past72Hours = diffInMs > hoursInMs;

    let label = 'Pending';
    let color: any = 'warning';
    if (shippedCount === totalOrders) {
      label = 'Completed';
      color = 'success';
    } else if (shippedCount > 0) {
      if (past72Hours) {
        label = 'In progress (alert)';
        color = 'secondary';
      } else {
        label = 'In progress';
        color = 'info';
      }
    }

    return <Chip variant="outlined" size="small" color={color} label={label} sx={{ fontWeight: 'bold' }} />;
  };

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Batch Number',
      flex: 0.8,
    },
    {
      field: 'batch_name',
      headerName: 'Batch Name',
      flex: 2,
      renderCell: ({ value, row }) => (
        <Typography sx={row.status === 'error' ? { color: colors.redAccent, fontWeight: 'bold' } : undefined}>
          {value}
        </Typography>
      ),
    },
    {
      field: 'orders',
      headerName: 'Order Count',
      flex: 0.8,
      renderCell: ({ value }) => <Typography>{value.length}</Typography>,
    },
    {
      field: 'shipping_status',
      headerName: 'Batch Status',
      flex: 1.2,
      renderCell: ({ row }) => (row.status === 'error' ? <></> : getShippingStatusLabel(row.orders, row.created_at)),
    },
    {
      field: 'sort_status',
      headerName: 'Sorting Status',
      flex: 1,
      renderCell: ({ value, row }) => (
        <Chip
          variant="outlined"
          size="small"
          color={row.status === 'error' ? 'error' : value === 'complete' ? 'success' : 'info'}
          label={getSortingStatusLabel(value)}
          sx={{ fontWeight: 'bold' }}
        />
      ),
    },
    {
      field: 'created_at',
      headerName: 'Dates',
      flex: 1.5,
      renderCell: ({ value, row }) => (
        <Box sx={{ '& p': { fontSize: '13px' } }}>
          <Typography>Created: {formatDateTime(value)}</Typography>
          <Typography>Updated: {formatDateTime(row.updated_at)}</Typography>
        </Box>
      ),
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 1.5,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <Box flex={1} alignItems="center" display="flex" gap="10px">
          <RegularButton
            color="info"
            onClick={(e) => {
              setSelectedBatch(params.row);
              setOpenUpdateModal(true);
            }}
            label="Rename"
            variant="outlined"
            size="small"
          />
          <RegularButton
            color="success"
            onClick={() => {
              setSelectedBatch(params.row);
              setOpenViewOrders(true);
            }}
            label="View Orders"
            variant="outlined"
            size="small"
          />
        </Box>
      ),
    },
  ];

  const order_table_columns: GridColDef[] = [
    {
      field: 'order_name',
      headerName: 'Order No.',
      flex: 1,
    },
    {
      field: 'order_date',
      headerName: 'Order Date',
      flex: 1,
      renderCell: ({ value }) => <Typography>{formatDate(value)}</Typography>,
    },
    {
      field: 'line_items',
      headerName: 'SKU',
      flex: 1.5,
      sortable: false,
      renderCell: ({ row }) => (
        <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="10px">
          {row.line_items.map((item: any, index: number) => (
            <Typography key={index}>{item.sku}</Typography>
          ))}
        </Box>
      ),
    },
    {
      field: 'dear_errors',
      headerName: 'Errors',
      flex: 2,
      renderCell: ({ value }) => (
        <Box>
          {value?.map((error: any, index: number) => (
            <Typography key={index} color={colors.redAccent} fontSize="12px" sx={{ textWrap: 'wrap' }}>
              • {error.Exception}
            </Typography>
          ))}
        </Box>
      ),
    },
    {
      field: 'shipping_country',
      headerName: 'Shipping Country',
      flex: 1,
      sortable: false,
    },
    {
      field: 'fraudulent_status',
      headerName: 'Fraudulent Status',
      flex: 1,
      sortable: false,
    },
    {
      field: 'tote_bin_number',
      headerName: 'Tote Bin #',
      flex: 0.8,
    },
    {
      field: 'tracking_number',
      headerName: 'Tracking Number',
      flex: 1.5,
      renderCell: ({ value, row }) =>
        value ? (
          <Box>
            {row.shipping_carrier && (
              <Typography>
                {row.shipping_carrier.carrier_name} - {value}
              </Typography>
            )}

            {row.shipping_status && (
              <Chip
                size="small"
                color={row.shipping_status === 'shipped' ? 'success' : 'info'}
                label={capitalizeWord(row.shipping_status)}
                sx={{ paddingInline: '5px', fontWeight: 'bold' }}
              />
            )}
          </Box>
        ) : (
          <></>
        ),
    },
    {
      field: 'tags',
      headerName: 'Tags',
      flex: 1,
      sortable: false,
    },
    {
      field: 'note',
      headerName: 'Notes',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: selectedBatch?.status === 'error' ? 1 : 1.5,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <Box flex={1} justifyContent="center" display="flex" gap="10px" width="100%">
          {selectedBatch?.status === 'error' ? (
            <RegularButton
              color="success"
              onClick={(e) => {
                e.stopPropagation();
                setOpenResolveOrders(true);
                setSelectedOrders([params.row.order_name]);
              }}
              label="Resolve"
              variant="outlined"
              size="small"
            />
          ) : (
            <>
              {/* <RegularButton
                color="success"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedCarrier(undefined);
                  setSelectedOrder(params.row);
                  setLoadingPrint(true);
                  printLabel([params.row.order_name])
                    .then((res) => {
                      if (res.data)
                        downloadPdf(res.data, params.row.shipping_carrier?.carrier_name, params.row.order_name);
                    })
                    .finally(() => setLoadingPrint(false));
                }}
                label="Print Label"
                variant="outlined"
                size="small"
                disabled={!params.row.tracking_number}
                loading={loadingPrint}
              /> */}
              <RegularButton
                color="error"
                onClick={(e) => {
                  setSelectedOrder(params.row);
                  setOpenDeleteConfirmation(true);
                  e.stopPropagation();
                }}
                label="Remove"
                variant="outlined"
                size="small"
              />
            </>
          )}
        </Box>
      ),
    },
  ];

  const viewOrders = async (query: ApiQuery) => {
    if (selectedBatch) {
      const { data } = await getOrders({ ...query, batch_id: selectedBatch.id + '' });
      setSelectedOrdersDetails(data.rows);
      setOrderCount(data.count);
    }
  };

  const getAllBatches = async (query: ApiQuery) => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getBatches(query);
        setRows(res.data.rows);
        setRowCount(res.data.count);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching activity logs:', error);
      }
    };

    fetchData();
  };

  const downloadPdf = (blob: any, carrier: string, orderName?: string) => {
    const pdfBlob = new Blob([blob], { type: 'application/pdf' });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(pdfBlob);
    link.href = url;

    const company = carrier === SHIPPING_COMPANY.ARAMEX ? 'AR' : carrier === SHIPPING_COMPANY.JT ? 'JT' : 'NV';
    let label =
      orderName ?? `Combined (${selectedOrders.length ? selectedOrders.length : orderCount} of ${orderCount})`;
    if (selectedOrders.length === 1) {
      label = '-' + selectedOrders[0];
    }
    link.download = `Batch-SN-${selectedBatch?.id}-Shipping-Labels-${company}-${label}.pdf`;

    link.click();
    window.URL.revokeObjectURL(url);
  };

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true); //when button submit is clicked turns on the loading animation

    try {
      await updateBatch(data.id, data);
      enqueueSnackbar(`User successfully updated!`, { variant: 'success' });

      setOpenUpdateModal(false);
      batchTableRef.current.refreshData();
    } catch (error: any) {
      if (error.response?.data) {
        formikHelpers.setErrors(tranformFormErrors(error.response.data));
      }
    } finally {
      setButtonLoading(false);
    }
  };

  const countOccurrences = (array: string[]) => {
    let counts: any = {};

    array.forEach(function (item) {
      if (counts[item]) {
        counts[item]++;
      } else {
        counts[item] = 1;
      }
    });

    return counts;
  };

  const openErrors = () => {
    setOpenSummarizedErrors(true);
    const errors = selectedOrdersDetails.flatMap((order) => order.dear_errors?.map((error) => error.Exception));
    const uniqueErrors = countOccurrences(errors);
    setSummarizedErrors(Object.keys(uniqueErrors).map((key) => ({ error: key, count: uniqueErrors[key] })));
  };

  const handleSubmitProcessShipment = async (data: any, formikHelpers: FormikHelpers<any>) => {
    if (selectedCarrier) processOrderShipment(selectedCarrier, data.pickup_date);
  };

  const disableCommercialInvoiceOrPrintLabel: boolean = useMemo(() => {
    const orders = selectedOrders.length
      ? selectedOrdersDetails.filter((item) => selectedOrders.includes(item.order_name))
      : selectedOrdersDetails;
    return orders.some((order) => !order.tracking_number);
  }, [selectedOrders, selectedOrdersDetails]);

  return (
    <Box>
      <Header title="Batches" mb="0" />
      <CustomTable
        ref={batchTableRef}
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        getData={getAllBatches}
        searchKeys="batch name or number"
      />
      <CustomModal header={'Rename Batch'} open={openUpdateModal} setOpen={setOpenUpdateModal} width={500}>
        <CustomForm
          initialValues={selectedBatch}
          onSubmit={handleSubmit}
          fields={[
            {
              field_name: 'batch_name',
              display_name: 'Batch Name',
              span: 4,
            },
          ]}
          loading={buttonLoading}
        />
      </CustomModal>

      <CustomModal
        header={`SN-${selectedBatch?.id}: ${selectedBatch?.batch_name} - Orders Added ( ${selectedOrdersDetails.length} )`}
        open={openViewOrders}
        setOpen={setOpenViewOrders}
        width={'90%'}
        onClose={() => {
          setSelectedOrders([]);
          setSelectedOrdersDetails([]);
          setOrderCount(0);
          setSelectedBatch(undefined);
          batchTableRef.current.refreshData();
        }}
      >
        <Box display="none">
          <Box ref={printableRef}>
            {selectedOrdersDetails
              .filter((order) => !selectedOrders.length || selectedOrders.includes(order.order_name))
              .map((order) => (
                <PickList
                  key={order.order_name}
                  batchNumber={selectedBatch?.id!}
                  order={order}
                  skuLocations={skuLocations}
                />
              ))}
          </Box>
          <Box ref={printableCombineRef}>
            <CombinePickList
              batchNumber={selectedBatch?.id!}
              orders={selectedOrdersDetails.filter(
                (order) => !selectedOrders.length || selectedOrders.includes(order.order_name)
              )}
              skuLocations={skuLocations}
            />
          </Box>
          <Box ref={printableCommercialInvoice}>
            {selectedOrdersDetails
              .filter((order) => !selectedOrders.length || selectedOrders.includes(order.order_name))
              .map((order) => (
                <CommercialInvoice key={order.order_name} order={order} skuLocations={skuLocations} />
              ))}
          </Box>
        </Box>

        <CustomTable
          ref={orderTableRef}
          rowId="order_name"
          columns={
            selectedBatch?.status === 'error'
              ? order_table_columns.filter(
                  (column) => !['tote_bin_number', 'note', 'tracking_number'].includes(column.field)
                )
              : order_table_columns.filter((column) => column.field !== 'dear_errors')
          }
          rows={selectedOrdersDetails}
          rowCount={orderCount}
          loading={loading}
          getData={(query) => viewOrders(query)}
          searchKeys="order no., tags, or notes"
          keepNonExistentRowsSelected
          checkboxSelection
          autoSelectedRows={selectedOrders}
          handleSelectRow={(selected) => setSelectedOrders(selected as any)}
          initialOrderBy="tote_bin_number"
          headerComponent={
            selectedBatch?.status === 'error' ? (
              <Box display="flex" gap="10px">
                <RegularButton
                  label={'Resolve Selected Orders'}
                  disabled={!selectedOrders.length}
                  onClick={() => setOpenResolveOrders(true)}
                />
                <RegularButton label={'View Summarized Errors'} onClick={openErrors} />
              </Box>
            ) : (
              <Box display="flex" gap="10px" alignItems="center">
                <RegularButton
                  label={'Generate pick list'}
                  onClick={() => {
                    setDocumentToPrint('Pick-List');
                    handlePrintPickList();
                  }}
                  loading={skuLocationsLoading}
                />
                <RegularButton
                  color="warning"
                  label={'Generate combine pick list'}
                  onClick={() => {
                    setDocumentToPrint('Combine-Pick-List');
                    handlePrintCombinePickList();
                  }}
                  loading={skuLocationsLoading}
                />
                <RegularButton
                  color="info"
                  label={'Generate Commercial Invoice'}
                  onClick={() => {
                    let label = ` (${selectedOrders.length ? selectedOrders.length : orderCount} of ${orderCount})`;
                    if (selectedOrders.length === 1) {
                      label = '-' + selectedOrders[0];
                    }
                    setDocumentToPrint(`CI-Combined${label}`);
                    handlePrintCommercialInvoice();
                  }}
                  loading={skuLocationsLoading}
                  disabled={disableCommercialInvoiceOrPrintLabel}
                />
                <RegularButton
                  color="success"
                  label={'Reprint Label'}
                  onClick={() => {
                    const selected = selectedOrdersDetails.filter(
                      (order) => !selectedOrders.length || selectedOrders.includes(order.order_name)
                    );

                    setLoadingPrint(true);
                    printLabel(selected.map((order) => order.order_name))
                      .then((res) => {
                        if (res.data)
                          downloadPdf(
                            res.data,
                            selected[0]?.shipping_carrier.carrier_name,
                            selected.length === 1 ? selected[0].order_name : undefined
                          );
                      })
                      .finally(() => setLoadingPrint(false));
                  }}
                  loading={loadingPrint}
                  disabled={disableCommercialInvoiceOrPrintLabel}
                />
                {selectedOrders.length > 0 && <Typography fontWeight="bold">for selected orders</Typography>}
              </Box>
            )
          }
          filterComponent={
            selectedBatch?.status === 'error' ? undefined : (
              <Box display="flex" gap="10px" alignItems="center">
                <Typography fontWeight="bold">Process Shipment:</Typography>
                {user?.shipping_carriers.map((carrier, index) => (
                  <RegularButton
                    key={index}
                    color={['info', 'secondary', 'warning'][index % 3] as any}
                    variant="outlined"
                    label={carrier.carrier_name}
                    onClick={() => {
                      setSelectedCarrier(carrier.carrier_name);
                      setOpenProcessShipment(true);
                    }}
                    styles={{ width: '120px' }}
                    disabled={!selectedOrders.length}
                    loading={processShipmentLoading}
                  />
                ))}
              </Box>
            )
          }
        />
      </CustomModal>

      <CustomModal
        header={`Summarized Errors`}
        open={openSummarizedErrors}
        setOpen={setOpenSummarizedErrors}
        width={800}
        onClose={() => setSummarizedErrors([])}
      >
        <Box display="grid" gridTemplateColumns="60px 1fr" width="100%" rowGap="5px">
          <Typography fontWeight="bold">Count</Typography>
          <Typography fontWeight="bold">Error</Typography>
        </Box>
        <Box display="grid" gridTemplateColumns="60px 1fr" width="100%" rowGap="5px">
          {summarizedErrors.map((error, index) => (
            <Fragment key={index}>
              <Typography>{error.count}</Typography>
              <Typography>{error.error}</Typography>
            </Fragment>
          ))}
        </Box>
      </CustomModal>

      <CustomModal
        header={`Set Pickup Date - ${selectedCarrier}`}
        open={openProcessShipment}
        setOpen={setOpenProcessShipment}
        width={500}
        onClose={() => setSummarizedErrors([])}
      >
        <CustomForm
          initialValues={{ pickup_date: '' }}
          onSubmit={handleSubmitProcessShipment}
          fields={[
            {
              field_name: 'pickup_date',
              display_name: 'Pickup Date',
              type: 'date',
              span: 4,
            },
          ]}
          loading={processShipmentLoading}
          schema={process_shipment_schema}
        />
      </CustomModal>

      <OrderValidation
        title="Resolve Selected Orders"
        open={openResolveOrders}
        setOpen={setOpenResolveOrders}
        orders={selectedOrders}
        batch_number={selectedBatch?.id}
        onClose={() => {
          setSelectedOrders([]);
          orderTableRef.current.refreshData();
        }}
      />

      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure want to remove this order?"
        onConfirm={() =>
          removeFromBatch(selectedOrder?.id!).then(() => {
            orderTableRef.current.refreshData();
          })
        }
      />
    </Box>
  );
};

export default Batches;
