import { Box, Typography } from '@mui/material';
import { ConfirmationDialog, CustomForm, CustomIconButton, CustomModal, RegularButton, RowAction } from 'components';
import { createOrderFilter, deleteOrderFilter, getOrderFilters, updateOrderFilter } from 'api/order-filter';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { FormikHelpers } from 'formik';
import { OrderFilterModel } from 'model/Entities';
import { filter_schema } from 'model/schema';

type Props = {
  handleUpdateFilter: (value: any) => void;
};

const OrderPresetFilter = forwardRef((props: Props, ref) => {
  const { handleUpdateFilter } = props;
  useImperativeHandle(ref, () => ({ getFilters }));

  const [filters, setFilters] = useState<OrderFilterModel[]>([]);
  const [activeFilter, setActiveFilter] = useState<OrderFilterModel>();
  const [loading, setLoading] = useState(false);
  const [openCreateFilter, setOpenCreateFilter] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<OrderFilterModel>();

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateActivetFilter = (filter?: OrderFilterModel) => {
    setActiveFilter(filter);
    handleUpdateFilter(filter);
  };

  const getFilters = (setLastAsActive?: boolean) => {
    getOrderFilters({ length: 100 }).then((res) => {
      const data: OrderFilterModel[] = res.data.rows;
      setFilters(data);
      if (setLastAsActive) updateActivetFilter(data[data.length - 1]);
      else handleUpdateFilter(data.find((filter) => filter.id === activeFilter?.id));
    });
  };

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    try {
      setLoading(true);
      if (selectedFilter) {
        data.filter = selectedFilter.filter;
        await updateOrderFilter(selectedFilter.id!, data);
        getFilters();
      } else {
        data.filter = {};
        await createOrderFilter(data);
        getFilters(true);
      }

      setOpenCreateFilter(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      if (selectedFilter) {
        await deleteOrderFilter(selectedFilter.id!);
        getFilters();
        if (selectedFilter.id === activeFilter?.id) updateActivetFilter(undefined);
      }
      setOpenConfirmDelete(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box mb="10px">
      <Box display="flex" gap="10px" width="100%" alignItems="center">
        <Typography>Filters:</Typography>
        <RegularButton
          variant={!activeFilter ? undefined : 'outlined'}
          color="secondary"
          size="small"
          label={'All'}
          onClick={() => updateActivetFilter(undefined)}
          styles={{ borderRadius: '20px', padding: '5px 20px' }}
        />
        {filters.map((filter) => (
          <Box display="flex" alignItems="center" key={filter.id}>
            <RegularButton
              variant={activeFilter?.id === filter.id ? undefined : 'outlined'}
              color="secondary"
              size="small"
              label={filter.filter_name}
              onClick={() => updateActivetFilter(filter)}
              styles={{ borderRadius: '20px', padding: '5px 20px' }}
            />
            <RowAction
              actions={[
                {
                  label: 'Rename',
                  startIcon: <DriveFileRenameOutlineOutlinedIcon />,
                  action: () => {
                    setOpenCreateFilter(true);
                    setModalHeader('Rename Filter');
                    setSelectedFilter(filter);
                  },
                },
                {
                  label: 'Delete',
                  startIcon: <DeleteOutlinedIcon />,
                  action: () => {
                    setSelectedFilter(filter);
                    setOpenConfirmDelete(true);
                  },
                },
              ]}
            />
          </Box>
        ))}
        <CustomIconButton
          icon={<AddIcon />}
          onClick={() => {
            setOpenCreateFilter(true);
            setModalHeader('Create Filter');
            setSelectedFilter(undefined);
          }}
          tooltip="Create Filter"
        />
      </Box>
      <CustomModal header={modalHeader} open={openCreateFilter} setOpen={setOpenCreateFilter} width={500}>
        <CustomForm
          fields={[{ field_name: 'filter_name', display_name: 'Filter Name', span: 4 }]}
          onSubmit={handleSubmit}
          initialValues={{ filter_name: selectedFilter?.filter_name ?? '' }}
          schema={filter_schema}
          loading={loading}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        message={`Are you sure you want to remove the ${selectedFilter?.filter_name} filter?`}
        onConfirm={handleDelete}
        loading={loading}
      />
    </Box>
  );
});

export default OrderPresetFilter;
