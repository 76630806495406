import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { CustomForm } from 'components';
import { login } from 'api/user';
import { login_schema } from 'model/schema';
import { tokens } from 'context/theme.context';

function LoginForm() {
  const colors = tokens();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const loginUser = async (data: any) => {
    try {
      setLoading(true);
      const response = await login(data);
      if (response.data.accessToken) {
        localStorage.setItem('access_token', response.data.accessToken);
        localStorage.setItem('refresh_token', response.data.refreshToken);
        if (locationState) {
          const { redirectTo } = locationState as any;
          navigate(`${redirectTo.pathname}${redirectTo.search}`);
        } else {
          navigate('/');
        }
      } else {
        // setError('Login Failed');
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        // setError(error.response.data.msg);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      navigate('/');
    }

    if (locationState) {
      const { redirectTo } = locationState as any;
      if (redirectTo) {
        // setError('User is not signed in.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
      <Box
        width="100%"
        maxWidth="500px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="40px"
        sx={{
          backgroundColor: colors.secondary_background,
          padding: '50px 80px',
          borderRadius: '20px',
        }}
      >
        <img alt="LOGO" width="180px" src={`../../assets/imin-logo.webp`} />

        <Box width="100%">
          <CustomForm
            fields={[
              { field_name: 'username', display_name: 'Username', span: 4 },
              { field_name: 'password', display_name: 'Password', type: 'password', span: 4 },
            ]}
            initialValues={{ username: '', password: '' }}
            onSubmit={loginUser}
            loading={loading}
            schema={login_schema}
            submitButtonPlacement="center"
            submitButtonText="Sign In"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default LoginForm;
