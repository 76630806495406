import {
  CustomAutoCompleteMultipleSelect,
  CustomAutoCompleteSelect,
  CustomCheckbox,
  CustomDatePicker,
  CustomPasswordField,
  CustomSelectField,
  CustomTextField,
  CustomYearPicker,
  SubmitButton,
} from 'components';
import { Formik, FormikHelpers } from 'formik';

import { Box } from '@mui/material';
import { FieldInput } from 'model/interface';
import React from 'react';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';

type CustomFormProps = {
  onSubmit: (data: any, formikHelpers: FormikHelpers<any>) => void;
  children?: React.ReactNode;
  fields: FieldInput[];
  schema?: any;
  initialValues: any;
  submitButtonText?: string;
  submitButtonPlacement?: 'center' | 'flex-end' | 'flex-start';
  disabled?: boolean;
  loading?: boolean;
  gap?: string;
};

const CustomForm: React.FC<CustomFormProps> = ({
  onSubmit,
  fields,
  schema,
  initialValues,
  submitButtonText,
  submitButtonPlacement = 'flex-end',
  disabled,
  loading,
  gap = '25px',
}) => {
  const isNonMobile = useMediaQuery('(min-width:600px)');

  const handleFormSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
    const date_fields = fields.filter((field) => field.type === 'date' || field.type === 'datetime');
    for (let index = 0; index < date_fields.length; index++) {
      const field_name = date_fields[index].field_name;
      const field_type = date_fields[index].type;
      const date_value = new Date(values[field_name]);

      if (field_type === 'date') values[field_name] = moment(date_value).format('YYYY-MM-DD');
      else if (field_type === 'datetime') values[field_name] = moment(date_value).format('YYYY-MM-DD HH:mm:ss');
    }

    onSubmit(values, formikHelpers);
  };

  return (
    <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={schema} enableReinitialize>
      {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            columnGap={gap}
            rowGap={gap}
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
            }}
          >
            {fields.map((field, index) => {
              if (field.hidden) {
                return false;
              }
              if (field.type === 'select') {
                return (
                  <CustomSelectField
                    key={index}
                    label={field.display_name}
                    disabled={field.disabled}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values[field.field_name]}
                    fieldName={field.field_name}
                    error={errors[field.field_name] as string}
                    touched={touched[field.field_name] as boolean}
                    span={field.span ?? 2}
                    options={field.options}
                    required={field.required}
                    handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                    size={field.size}
                    helperText={field.helperText}
                  />
                );
              }
              if (field.type === 'checkbox') {
                return (
                  <CustomCheckbox
                    key={index}
                    label={field.display_name!}
                    handleChange={handleChange}
                    disabled={field.disabled}
                    fieldName={field.field_name}
                    value={values[field.field_name]}
                    span={field.span ?? 2}
                    handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                  />
                );
              }
              if (field.type === 'year') {
                return (
                  <CustomYearPicker
                    key={index}
                    label={field.display_name}
                    disabled={field.disabled}
                    fieldName={field.field_name}
                    span={field.span ?? 2}
                    setFieldValue={setFieldValue}
                    value={values[field.field_name]}
                  />
                );
              }
              if (field.type === 'date') {
                return (
                  <CustomDatePicker
                    key={index}
                    fieldName={field.field_name}
                    label={field.display_name!}
                    disabled={field.disabled}
                    value={values[field.field_name]}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    error={errors[field.field_name]}
                    touched={touched[field.field_name] as boolean}
                    span={field.span ?? 2}
                    disablePast={field.disablePast}
                    disableFuture={field.disableFuture}
                    size={field.size}
                  />
                );
              }
              if (field.type === 'multiselect') {
                return (
                  <CustomAutoCompleteMultipleSelect
                    key={index}
                    label={field.display_name!}
                    disabled={field.disabled}
                    setFieldValue={setFieldValue}
                    value={values[field.field_name]}
                    fieldName={field.field_name}
                    error={errors[field.field_name] as string}
                    touched={touched[field.field_name] as boolean}
                    span={field.span ?? 2}
                    options={field.options!}
                    required={field.required}
                    size={field.size}
                    handleChange={handleChange}
                    handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                  />
                );
              }
              if (field.type === 'autocomplete') {
                return (
                  <CustomAutoCompleteSelect
                    key={index}
                    label={field.display_name!}
                    disabled={field.disabled}
                    setFieldValue={setFieldValue}
                    value={values[field.field_name]}
                    fieldName={field.field_name}
                    error={errors[field.field_name] as string}
                    touched={touched[field.field_name] as boolean}
                    span={field.span ?? 2}
                    options={field.options!}
                    required={field.required}
                    size={field.size}
                    handleChange={handleChange}
                    handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                  />
                );
              }
              if (field.type === 'password') {
                return (
                  <CustomPasswordField
                    key={field.field_name}
                    label={field.display_name!}
                    disabled={field.disabled}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values[field.field_name]}
                    fieldName={field.field_name}
                    error={errors[field.field_name]}
                    touched={touched[field.field_name] as boolean}
                    span={field.span ?? 2}
                    size={field.size}
                  />
                );
              }
              return (
                <CustomTextField
                  key={index}
                  type={field.type}
                  label={field.display_name}
                  disabled={field.disabled}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  value={values[field.field_name]}
                  fieldName={field.field_name}
                  error={errors[field.field_name]}
                  touched={touched[field.field_name] as boolean}
                  span={field.span ?? 2}
                  required={field.required}
                  multiline={field.multiline}
                  rows={field.rows}
                  autoFocus={field.autoFocus}
                />
              );
            })}
          </Box>
          <Box display="flex" justifyContent={submitButtonPlacement} mt={4} pb="15px">
            <SubmitButton label={submitButtonText} loading={loading} disabled={disabled} />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CustomForm;
