import { tokens } from 'context/theme.context';
import { useEffect } from 'react';

type Props = {
  componentRef?: any;
  value: any;
  setValue: (value: any) => void;
  placeholder?: string;
  handleKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
};

const AutoFocusTextField: React.FC<Props> = ({ componentRef, value, setValue, placeholder, handleKeyPress }) => {
  const colors = tokens();

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Tab' && componentRef?.current) {
        event.preventDefault(); // Prevent the tab from moving focus
      }
    };
    document.addEventListener('keydown', handleKeyDown);
  });

  return (
    <input
      ref={componentRef ? (el) => (componentRef.current = el) : undefined}
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={handleKeyPress}
      placeholder={placeholder}
      autoFocus
      style={{
        backgroundColor: colors.inputBackground,
        border: 'none',
        borderRadius: '10px',
        padding: '15px 16px',
        color: colors.text,
        fontSize: '16px',
      }}
    />
  );
};

export default AutoFocusTextField;
