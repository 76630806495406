import { Box, Button, CircularProgress, SxProps, Theme } from '@mui/material';

import { CheckCircleOutline } from '@mui/icons-material';

type SubmitButtonProps = {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  width?: string;
  isSuccess?: boolean;
  id?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  submitButtonStyles?: SxProps<Theme>;
};

const SubmitButton: React.FC<SubmitButtonProps> = ({
  label,
  loading,
  disabled,
  width,
  isSuccess,
  id,
  startIcon,
  endIcon,
  size,
  submitButtonStyles,
}) => {
  return isSuccess ? (
    <Box display="flex" alignItems="center" justifyContent="center">
      <CheckCircleOutline fontSize="large" sx={{ color: '#02ad2e' }} />
    </Box>
  ) : (
    <Button
      type="submit"
      variant="contained"
      disabled={disabled || loading || isSuccess}
      sx={{
        textTransform: 'none',
        borderRadius: 2,
        padding: size === 'small' ? '4px 25px' : '7px 25px',
        width: width,
        '@media screen and (max-width: 768px)': { width: '140px', height: '40px', padding: '0px' },
        ...submitButtonStyles,
      }}
      id={id}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
    >
      {!loading ? label || 'Submit' : <CircularProgress color="inherit" size="1.3rem" />}
    </Button>
  );
};

export default SubmitButton;
