import 'styles/Components.css';

import { Box, MenuItem, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import CustomDateRangeFilter, { DateRangeValue } from './CustomDateRangeFilter';

import { SelectOption } from 'model/interface';
import { getDateRange } from 'utils';
import { tokens } from 'context/theme.context';

export type QuickFilterProps = {
  startDate?: string;
  endDate?: string;
  value: string;
};

type CustomDateQuickFilterProps = {
  label: string;
  value?: any;
  disabled?: boolean;
  handleChangeCallback?: (value: QuickFilterProps) => void;
  size?: 'small' | 'medium' | undefined;
  pastOnly?: boolean;
  futureOnly?: boolean;
};

const CustomDateQuickFilter: React.FC<CustomDateQuickFilterProps> = ({
  label,
  value,
  disabled,
  handleChangeCallback,
  size = 'small',
  pastOnly,
  futureOnly,
}) => {
  const colors = tokens();
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>(value ?? '');
  const [filterDateRange, setFilterDateRange] = useState<DateRangeValue>([undefined, undefined]);

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedOption(event.target.value);
    const val = getDateRange(event.target.value);
    if (val.value === 'Custom') {
      setFilterDateRange([val.startDate, val.endDate]);
    } else {
      handleChangeCallback && handleChangeCallback(val);
    }
  };

  useEffect(() => {
    handleChangeCallback &&
      handleChangeCallback({ startDate: filterDateRange[0], endDate: filterDateRange[1], value: 'Custom' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDateRange]);

  useEffect(() => {
    setSelectedOption(value ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    let list = [{ key: 'Today', value: 'Today' }];
    if (!futureOnly) {
      list = [...list, { key: 'Yesterday', value: 'Yesterday' }, { key: 'Custom', value: 'Custom' }];
    }
    if (!pastOnly) {
      list = [...list, { key: 'Tomorrow', value: 'Tomorrow' }, { key: 'Custom', value: 'Custom' }];
    }

    setOptions(list);
  }, [pastOnly, futureOnly]);

  return (
    <Box flex="1" display="flex" gap="10px" alignItems={'flex-start'} flexDirection="column" sx={{ minWidth: '200px' }}>
      <TextField
        fullWidth
        select
        variant="filled"
        label={label}
        disabled={disabled}
        onChange={handleSelectChange}
        onSelect={(e) => undefined}
        value={selectedOption}
        sx={{
          '.MuiFormLabel-root.Mui-focused': { color: colors.light },
          '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none' },
          '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
          '.MuiFilledInput-root:before': { border: 'none' },
          '.Mui-disabled:before': { border: 'none !important' },
          '.MuiFilledInput-root:after': {
            borderBottom: `1px solid ${colors.primary}`,
            borderRadius: 2,
            height: '100%',
          },
          '.MuiFilledInput-input': { height: '24px' },
          '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
        }}
        size={size}
      >
        <MenuItem value="" disabled={!options?.length}>
          Select {label}
        </MenuItem>
        {options?.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.key}
          </MenuItem>
        ))}
      </TextField>
      {selectedOption === 'Custom' && (
        <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} filterDateRange={filterDateRange} />
      )}
    </Box>
  );
};

export default CustomDateQuickFilter;
