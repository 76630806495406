import 'styles/Components.css';

import { TextField } from '@mui/material';
import { tokens } from 'context/theme.context';

type CustomTextFieldProps = {
  type?: React.HTMLInputTypeAttribute;
  label: string;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  value?: unknown;
  fieldName: string;
  disabled?: boolean;
  touched?: boolean;
  error?: any;
  missing?: boolean;
  span?: number;
  required?: boolean;
  size?: 'small' | 'medium' | undefined;
  multiline?: boolean;
  rows?: number;
  autoFocus?: boolean;
  readOnly?: boolean;
  sx?: any;
};

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  type,
  label,
  handleBlur,
  handleChange,
  onKeyDown,
  value,
  fieldName,
  disabled,
  touched,
  error,
  missing,
  span,
  required,
  size = 'small',
  multiline,
  rows,
  autoFocus,
  readOnly,
  sx,
}) => {
  const colors = tokens();

  return (
    <TextField
      fullWidth
      variant="filled"
      type={type}
      label={label}
      autoFocus={autoFocus}
      disabled={disabled}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      value={value}
      name={fieldName}
      error={!!touched && !!error}
      helperText={touched && error}
      required={required}
      size={size}
      multiline={multiline}
      minRows={rows}
      inputProps={{ readOnly: readOnly }}
      sx={{
        '.MuiFormLabel-root.Mui-focused': { color: colors.light },
        '.MuiFilledInput-root': {
          borderRadius: 2,
          borderBottom: 'none',
          border: missing && !value ? '1px solid red' : '',
        },
        '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
        '.MuiFilledInput-root:before': { border: 'none' },
        '.Mui-disabled:before': { border: 'none !important' },
        '.MuiFilledInput-root:after': { borderBottom: `1px solid ${colors.primary}`, borderRadius: 2, height: '100%' },
        '.MuiFilledInput-input': { height: '24px' },
        '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
        '& .MuiInputBase-root-MuiFilledInput-root:hover:before': { borderBottom: 'none !important' },
        gridColumn: span ? 'span ' + span : undefined,
        ...sx,
      }}
    />
  );
};

export default CustomTextField;
