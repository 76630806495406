import { ForbiddenScreen, LoadingScreen } from 'screens';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import Layout from 'screens/Layout/layout';
import { UserContext } from 'context/user.context';
import { getCurrentUser } from 'api/user';

type ProtectedRouteProps = {
  requiredAuth?: string[];
  requiredRole?: string[];
  element?: JSX.Element;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredAuth, requiredRole, element }) => {
  const auth = localStorage.getItem('access_token');
  const { user, setUser } = useContext(UserContext);
  const location = useLocation();
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth && !user) {
      getCurrentUser().then((res) => {
        setUser(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, user]);

  useEffect(() => {
    checkUserAuthority();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, auth, requiredAuth, requiredRole]);

  const checkUserAuthority = () => {
    if (!requiredAuth && !requiredRole) {
      setLoading(false);
      setVerified(!!auth);
    } else {
      if (user) {
        if (requiredRole) {
          const verified_roles = requiredRole.filter((r) => user.user_group.group_name === r);
          setLoading(false);
          setVerified(!!verified_roles.length);
        } else if (requiredAuth) {
          const verified_auths = requiredAuth.filter((r) => user.authorities?.includes(r));
          setLoading(false);
          setVerified(!!verified_auths.length);
        }
      }
    }
  };

  const displayElement =
    requiredAuth || requiredRole ? (
      <>{element ?? <Outlet />}</>
    ) : (
      <Layout>
        <Outlet />
      </Layout>
    );

  if (!loading && !verified) {
    return requiredAuth || requiredRole ? (
      <ForbiddenScreen />
    ) : (
      <Navigate to={'/login'} state={{ redirectTo: location }} />
    );
  }

  return !loading ? displayElement : <LoadingScreen />;
};

export default ProtectedRoute;
