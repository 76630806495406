import 'styles/Components.css';

import { ChangeEvent, useEffect, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';

import { SelectOption } from 'model/interface';
import { tokens } from 'context/theme.context';

type CustomSelectFieldProps = {
  label: string;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: unknown;
  fieldName: string;
  disabled?: boolean;
  touched?: boolean;
  error?: string;
  span?: number;
  options?: SelectOption[];
  handleChangeCallback?: (value: string) => void;
  required?: boolean;
  size?: 'small' | 'medium' | undefined;
  selected?: any;
  helperText?: string;
};

const CustomSelectField: React.FC<CustomSelectFieldProps> = ({
  label,
  handleBlur,
  handleChange,
  value,
  fieldName,
  disabled,
  touched,
  error,
  span,
  options,
  required,
  handleChangeCallback,
  size = 'small',
  selected,
  helperText,
}) => {
  const colors = tokens();
  const [selectedOption, setSelectedOption] = useState<any>(value);

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedOption(event.target.value);
    if (handleChange) handleChange(event);
    if (handleChangeCallback) handleChangeCallback(event.target.value);
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <TextField
      fullWidth
      select
      variant="filled"
      label={label}
      disabled={disabled}
      onBlur={handleBlur}
      onChange={handleSelectChange}
      onSelect={(e) => undefined}
      value={selectedOption}
      name={fieldName}
      error={!!touched && !!error}
      helperText={touched && error ? error : helperText}
      required={required}
      sx={{
        '.MuiFormLabel-root.Mui-focused': { color: colors.light },
        '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none' },
        '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
        '.MuiFilledInput-root:before': { border: 'none' },
        '.Mui-disabled:before': { border: 'none !important' },
        '.MuiFilledInput-root:after': { borderBottom: `1px solid ${colors.primary}`, borderRadius: 2, height: '100%' },
        '.MuiFilledInput-input': { height: '24px' },
        '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
        gridColumn: span ? 'span ' + span : undefined,
      }}
      size={size}
    >
      <MenuItem value="" disabled={!options?.length}>
        Select {label}
      </MenuItem>
      {options?.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.key}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CustomSelectField;
