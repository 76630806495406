import { Box, Typography } from '@mui/material';
import { formatArray, formatDate } from 'utils';

import Barcode from 'react-barcode';
import { OrderModel } from 'model/Entities';
import Watermark from './Watermark';
import { useMemo } from 'react';

type Props = {
  order: OrderModel;
  batchNumber: number;
  skuLocations: any;
};

const PickList: React.FC<Props> = ({ order, batchNumber, skuLocations }) => {
  const shipping_address = order.shopify_data?.shipping_address ?? {};
  const totalQuantity = useMemo(
    () =>
      order.line_items.reduce((total, item) => {
        return total + item.quantity;
      }, 0),
    [order]
  );

  return (
    <Box position="relative">
      <style>{styles}</style>
      <Watermark />

      <Box className="page-break" paddingTop="100px">
        <Box display="flex" width="100%" gap="20px" mb="30px">
          <Box flex="1" sx={{ '& p': { fontSize: '22px', fontWeight: 'bold' } }}>
            <Typography>Batch #: SN-{batchNumber}</Typography>
            <Typography>Tote Bin #: {order.tote_bin_number}</Typography>
            <Typography>PICK LIST</Typography>
          </Box>
          <Box flex="1">
            <Barcode value={order.order_name} height={70} fontSize={16} />
          </Box>
        </Box>

        <Box display="flex" width="100%" gap="20px" mb="20px">
          <Box flex="2">
            <Typography fontWeight="bold">Ship To:</Typography>
            <Typography>
              {shipping_address.first_name} {shipping_address.last_name}
            </Typography>
            <Typography>
              {formatArray([shipping_address.address1, shipping_address.address2, shipping_address.zip])}
            </Typography>
            <Typography>{formatArray([shipping_address.city, shipping_address.country])}</Typography>
          </Box>
          <Box flex="1">
            <Typography fontWeight="bold">Shopify Order #</Typography>
            <Typography>{order.order_name}</Typography>
            <br />
            <Typography fontWeight="bold">DEAR Order #</Typography>
            <Typography>{order.dear_order_number}</Typography>
          </Box>
          <Box flex="1">
            <Typography fontWeight="bold">Order Date</Typography>
            <Typography>{formatDate(order.order_date)}</Typography>
            <br />
            <Typography fontWeight="bold">Tags</Typography>
            <Typography>{order.tags || '-'}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            '& tbody th': { fontSize: '16px', padding: '5px', background: '#f0f0f0' },
            '& td': { fontSize: '15px', verticalAlign: 'top', padding: '5px' },
            '& tbody td': { borderBottom: '.5px solid #a3a3a3' },
            marginTop: '-120px',
          }}
        >
          <table width="100%" style={{ border: 'none' }} cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th className="header" align="left" colSpan={6}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th align="left" style={{ width: '5%' }}>
                  #
                </th>
                <th align="left" style={{ width: '10%' }}>
                  SKU
                </th>
                <th align="left" style={{ width: '15%' }}>
                  SKU Loc
                </th>
                <th style={{ width: '10%' }}>Qty</th>
                <th style={{ width: '10%' }}>Unit</th>
                <th align="left" style={{ width: '35%' }}>
                  Product Description
                </th>
              </tr>
              {order.line_items.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.sku}</td>
                  <td>{skuLocations[item.sku]?.stock_locator}</td>
                  <td style={{ textAlign: 'center', backgroundColor: item.quantity > 1 ? '#ffff80' : undefined }}>
                    {item.quantity}
                  </td>
                  <td style={{ textAlign: 'center' }}>Item</td>
                  <td>
                    {item.title} - {item.variant_title}
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td style={{ fontWeight: 'bold' }}>Total</td>
                <td style={{ textAlign: 'center' }}>{totalQuantity}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <Box width="100%" mt="30px">
            <Typography>Notes</Typography>
            <Typography>{order.note}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PickList;

const styles = `
  @page {
    size: A4;
    margin: 0;
    margin-bottom: 2cm;
  }
    
  .page-break:not(:first-child) {
    page-break-before: always;
  }

  .page-break {
    display: block;
    page-break-inside: avoid;
    padding: 100px 80px;
  }

  .header {
    padding-block: 60px;
  }

  thead th {
    background-color: white;
  }
`;
