export const SHIPPING_COMPANY = {
  ARAMEX: 'Aramex',
  JT: 'J&T Express',
  PICKUPP: 'Pickupp',
  NINJAVAN: 'Ninja Van',
  SINGAPORE_POST: 'Singapore Post',
};

export const SHIPPING_COMPANY_ID = {
  ARAMEX: 1,
  JT: 2,
  PICKUPP: 3,
  NINJAVAN: 4,
  SINGAPORE_POST: 5,
};
