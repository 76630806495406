import * as yup from 'yup';

export const login_schema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

export const user_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  user_group_id: yup.number().required('User Group is required.'),
  username: yup.string().matches(/^\S*$/, 'Username must not contain spaces').required('Username is required.'),
  password: yup
    .string()
    .required('Password is required.')
    .test('len', 'Password must be at least 8 characters long.', (val) => !val || val.length >= 8),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Contact Number is invalid.',
      excludeEmptyString: false,
    })
    .nullable(),
  email: yup.string().email('Must be a valid email address'),
});

export const update_user_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  user_group_id: yup.number().required('User Group is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Contact Number is invalid.',
      excludeEmptyString: false,
    })
    .nullable(),
  email: yup.string().email('Must be a valid email address'),
});

export const batch_schema = yup.object().shape({
  batch_name: yup.string().required('Batch Name is required'),
});

export const process_shipment_schema = yup.object().shape({
  pickup_date: yup.string().required('Pickup Date is required'),
});

export const stop_check_schema = yup.object().shape({
  stop_check_remarks: yup.string().required('Remarks is required'),
});

export const filter_schema = yup.object().shape({
  filter_name: yup.string().required('Filter Name is required'),
});
