import { SxProps, Theme } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RegularButton from './RegularButton';

type PrimaryButtonProps = {
  label?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  id?: string;
  width?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large';
  styles?: SxProps<Theme>;
  loading?: boolean;
};

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  label = 'Submit',
  onClick,
  disabled,
  id,
  fullWidth,
  size,
  styles,
  loading,
}) => {
  return (
    <RegularButton
      onClick={onClick}
      variant="contained"
      fullWidth={fullWidth}
      id={id}
      startIcon={<AddIcon />}
      disabled={disabled}
      label={label}
      size={size}
      styles={styles}
      loading={loading}
    />
  );
};

export default PrimaryButton;
