import { Box, Typography } from '@mui/material';

import { UserContext } from 'context/user.context';
import { useContext } from 'react';

const Watermark = () => {
  const { user } = useContext(UserContext);
  return (
    <Box position="fixed" top={0} right={0} display="flex" alignItems="flex-end" gap="20px" className="watermark">
      <Typography variant="h4">Printed By: {user.first_name}</Typography>
      <Typography variant="h4">|</Typography>
      <Typography variant="h4">Staff: _______________</Typography>
      <Box sx={{ backgroundColor: 'yellow' }} padding="20px 30px">
        <Typography variant="h1" fontWeight="bold">
          WUDI
        </Typography>
      </Box>
    </Box>
  );
};

export default Watermark;
