import { Divider, InputBase, SxProps, TextField, Theme, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from 'context/theme.context';

type SearchBarProps = {
  handleSearch: (searchKey: string) => void;
  searchKeys?: string;
  value?: string;
  label?: string;
  sx?: SxProps<Theme>;
  hideSearchIcon?: boolean;
};

const SearchBar: React.FC<SearchBarProps> = ({ handleSearch, searchKeys, value, label, sx, hideSearchIcon }) => {
  const colors = tokens();
  const [search, setSearch] = useState<string>('');
  const prevSearchKeyRef = useRef('');

  useEffect(() => setSearch(value ?? ''), [value]);

  const handleSearchClick = () => {
    if (search !== prevSearchKeyRef.current) {
      handleSearch(search);
      prevSearchKeyRef.current = search;
    }
  };

  const handleKeyPress: any = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: '300px',
          backgroundColor: '#f0f0f0',
          borderRadius: '10px',
          height: '48px',
          paddingLeft: '5px',
          alignSelf: 'flex-end',
          ...sx,
        }}
      >
        {!hideSearchIcon && (
          <>
            <IconButton type="button" onClick={() => handleSearchClick()}>
              <SearchIcon color="secondary" />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          </>
        )}
        <TextField
          fullWidth
          variant="filled"
          value={search}
          label={label ? label : `Search${searchKeys ? ' by ' + searchKeys : ''}`}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleKeyPress}
          onBlur={handleSearchClick}
          sx={{
            '.MuiFormLabel-root.Mui-focused': { color: colors.light },
            '.MuiFilledInput-root': {
              borderRadius: 2,
              borderBottom: 'none',
              background: 'none',
              width: '95%',

              // border: missing && !value ? '1px solid red' : '',
            },
            '.MuiFilledInput-root:hover': { backgroundColor: 'none' },
            '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
            '.MuiFilledInput-root:before': { border: 'none' },
            '.Mui-disabled:before': { border: 'none !important' },
            '.MuiFilledInput-root:after': {
              // borderBottom: `1px solid ${colors.primary}`,
              // borderRadius: 2,
              border: 'none',
            },
            '.MuiFilledInput-input': { height: '24px' },
            '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
            '& .MuiInputBase-root-MuiFilledInput-root:hover:before': { borderBottom: 'none !important' },
            // gridColumn: span ? 'span ' + span : undefined,
            ...sx,
          }}
        />
        {/* <InputBase
          sx={{ m: '10px', flex: 1, fontSize: '12px' }}
          placeholder={label ? label : `Search${searchKeys ? ' by ' + searchKeys : ''}`}
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={handleKeyPress}
          onBlur={handleSearchClick}
          value={search}
        /> */}
      </Box>
    </Box>
  );
};

export default SearchBar;
