import Dropdown, { DropdownOptionProps } from './Dropdown';

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { tokens } from 'context/theme.context';

type RowActionProps = {
  actions: DropdownOptionProps[];
  data?: any;
  disabled?: boolean;
};

const RowAction: React.FC<RowActionProps> = ({ actions, data, disabled }) => {
  const colors = tokens();

  return (
    <Dropdown
      isIcon
      optionList={actions}
      data={data}
      icon={<MoreVertOutlinedIcon sx={{ color: disabled ? colors.border : colors.secondary }} />}
      tooltip="View Actions"
      tooltipPlacement="left-end"
      disabled={disabled}
    />
  );
};

export default RowAction;
