import { Box, Typography } from '@mui/material';

import { ReactNode } from 'react';
import { tokens } from 'context/theme.context';

type Props = {
  field_name: string;
  field_value: string | number | ReactNode;
  flex?: number;
  isComponent?: boolean;
};

const DisplayField: React.FC<Props> = ({ field_name, field_value, isComponent, flex = 1 }) => {
  const colors = tokens();
  return (
    <Box flex={flex}>
      <Typography color={colors.accent} fontWeight="bold">
        {field_name}
      </Typography>
      {isComponent ? field_value : <Typography>{field_value}</Typography>}
    </Box>
  );
};

export default DisplayField;
