import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography } from '@mui/material';
import { AutoFocusTextField, CustomModal, CustomSwitch, Header, RegularButton } from 'components';
import { BatchModel, LineItemModel, OrderModel } from 'model/Entities';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import Barcode from 'react-barcode';
import { BreadcrumbContext } from 'context/breadcrumb.context';
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Howl } from 'howler';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import { addPickedCount } from 'api/order';
import { formatDateTime } from 'utils';
import { getBatch } from 'api/batch';
import { tokens } from 'context/theme.context';
import { useSnackbar } from 'notistack';

export const GIFTBOX_SKU = 'IMINGIFTBOX-SAVETHEWORLD';

const SortingStation = () => {
  const colors = tokens();
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [batch, setBatch] = useState<BatchModel>();
  const [batchNotFound, setBatchNotFound] = useState(false);
  const [openToteBinModal, setOpenToteBinModal] = useState(false);
  const [selectedLineItem, setSelectedLineItem] = useState<LineItemModel>();
  const [message, setMessage] = useState<ReactNode>();
  const [batchNumber, setBatchNumber] = useState<string>('');
  const [sku, setSku] = useState<string>('');
  const [allLineItems, setAllLineItems] = useState<LineItemModel[]>([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [removedToteBins, setRemovedToteBins] = useState<number[]>([]);
  const [manualMode, setManualMode] = useState(false);
  const batchRef = useRef<HTMLInputElement | null>();
  const skuRef = useRef<HTMLInputElement | null>();

  const giftBoxCount: number = useMemo(() => {
    if (!batch) return 0;
    return batch.orders
      .flatMap((order) => order.line_items)
      .reduce((total, item) => total + (item.sku === GIFTBOX_SKU ? item.quantity : 0), 0);
  }, [batch]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Sorting Station' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (batch?.sort_status === 'complete') {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch]);

  const handleUpdatePickedCount = async () => {
    if (selectedLineItem) {
      try {
        await addPickedCount(selectedLineItem.id);
        scanBatch(batch!.id + '');
      } catch (error) {
        enqueueSnackbar(`Error. Please scan again.`, { variant: 'error' });
      } finally {
        setOpenToteBinModal(false);
        setSku('');
      }
    }
  };

  const playSound = () => {
    const sound = new Howl({
      src: ['/error-sound.mp3'],
    });
    sound.play();
  };

  const scanBatch = async (value: string) => {
    setBatchNotFound(false);
    if (value) {
      try {
        const { data } = await getBatch(parseInt(value));
        if (data.status === 'error') data.orders = [];
        setBatch(data);
        setTimeout(() => {
          skuRef.current?.focus();
        }, 500);
      } catch (error) {
        console.error(error);
        setBatch(undefined);
        setBatchNotFound(true);
      }
    }
  };

  const scanSku = async (value: string) => {
    if (value) {
      const filtered = allLineItems.filter((line_item) => line_item.sku === value);

      if (filtered.length) {
        const found = filtered.find((line_item) => line_item.quantity - line_item.picked_count > 0);
        setSelectedLineItem(found);
        if (found) {
          setMessage(
            <Box display="flex" gap="5px" alignItems="center">
              <Typography fontSize="20px">
                Have you placed SKU <strong>{value}</strong> in
              </Typography>
              <Typography fontWeight="bold" fontSize="45px" color={colors.accent}>
                Tote Bin #{found.tote_bin_number}
              </Typography>
              <Typography fontSize="20px">?</Typography>
            </Box>
          );
        } else {
          setMessage(
            <Typography fontSize="20px" fontWeight="bold" color={colors.redAccent}>
              SKU {value} is more than what is needed in this batch.
            </Typography>
          );
          playSound();
        }
      } else {
        setMessage(
          <Typography fontSize="20px" fontWeight="bold" color={colors.redAccent}>
            SKU {value} was not found in this batch.
          </Typography>
        );
        playSound();
        setSelectedLineItem(undefined);
      }

      setOpenToteBinModal(true);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      scanBatch(batchNumber);
    }, 1000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchNumber]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!manualMode) scanSku(sku);
    }, 100);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku]);

  const closeModal = () => {
    setMessage(undefined);
    setSelectedLineItem(undefined);
    // skuRef.current?.focus();
    if (!manualMode) setSku('');
  };

  const getRowColor = useCallback((lineItem: LineItemModel) => {
    if (lineItem.picked_count > 0 && lineItem.picked_count < lineItem.quantity) {
      return '#f7d73d';
    } else if (lineItem.picked_count === lineItem.quantity) {
      return '#72d673';
    }
  }, []);

  const getOrderColor = useCallback((order: OrderModel) => {
    return order.line_items.every((line_item) => line_item.picked_count === line_item.quantity) ? '#72d673' : undefined;
  }, []);

  const handleKeyPress: any = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && manualMode) {
      scanSku(sku);
    }
  };

  useEffect(() => {
    if (batch) {
      setAllLineItems(
        batch.orders.flatMap((order) =>
          order.line_items.map((line_item) => ({ ...line_item, tote_bin_number: order.tote_bin_number }))
        )
      );

      const arr = batch.orders.map((order) => order.tote_bin_number);
      const max = Math.max(...arr);
      const min = Math.min(...arr);
      const missingNumbers = [];

      for (let i = min; i <= max; i++) {
        if (!arr.includes(i)) {
          missingNumbers.push(i);
        }
      }
      setRemovedToteBins(missingNumbers);
    }
  }, [batch]);

  return (
    <Box>
      <Header title="Sorting Station" />

      <Box mt="50px" display="flex" gap="10px" width="100%" alignItems="flex-start">
        <Box flex="1">
          <Box display="flex" alignItems="center" gap="10px">
            <Typography>Scan Batch Serial Number: </Typography>
            <AutoFocusTextField
              value={batchNumber}
              setValue={setBatchNumber}
              placeholder="Batch Number"
              componentRef={batchRef}
            />
          </Box>
          {batch && batch.status !== 'error' && (
            <Box mt="50px">
              <Typography fontWeight="bold" fontSize="24px" mb="10px">
                Sorting for Batch #{batch.id} ({batch.batch_name})
              </Typography>
              {!!removedToteBins.length && (
                <Typography fontSize="18px" mb="10px">
                  <b>Note:</b> The following tote bins have been removed: {removedToteBins.join(', ')}
                </Typography>
              )}
              {batch.sort_status !== 'complete' ? (
                <Box>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box display="flex" alignItems="center" gap="10px">
                      <Typography>Scan SKU: </Typography>
                      <AutoFocusTextField
                        value={sku}
                        setValue={setSku}
                        placeholder="SKU"
                        handleKeyPress={handleKeyPress}
                        componentRef={skuRef}
                      />
                    </Box>

                    <Box display="flex" gap="20px">
                      <CustomSwitch
                        value={manualMode}
                        label1=""
                        label2={manualMode ? 'Manual' : 'Auto'}
                        handleChange={(e, checked) => setManualMode(checked)}
                      />

                      <RegularButton
                        label="Scan"
                        onClick={() => scanSku(sku)}
                        styles={{ width: '115px', visibility: manualMode ? undefined : 'hidden' }}
                      />
                    </Box>
                  </Box>
                  {/* IMINGIFTBOX-SAVETHEWORLD */}
                  {giftBoxCount > 0 && (
                    <Box mt="40px">
                      <Typography fontWeight="bold">
                        You need {giftBoxCount} gift box{giftBoxCount > 1 ? 'es' : ''} for this batch.
                      </Typography>
                      <Barcode value={GIFTBOX_SKU} height={70} fontSize={16} />
                    </Box>
                  )}
                </Box>
              ) : (
                <Box>
                  <Typography fontSize="40px" fontWeight="bold" color={colors.greenAccent}>
                    Completed
                  </Typography>
                  <Typography mb="20px">Date and Time Completed: {formatDateTime(batch.updated_at)}</Typography>
                  <RegularButton
                    label="Sort New Batch"
                    onClick={() => {
                      setBatch(undefined);
                      setBatchNumber('');
                      batchRef.current?.focus();
                      setShowConfetti(false);
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
          {batchNotFound && (
            <Box mt="50px">
              <Typography fontSize="40px" color={colors.accent} alignItems="center" display="flex" gap="10px">
                <SentimentDissatisfiedOutlinedIcon sx={{ fontSize: '40px' }} /> Batch Not Found
              </Typography>
            </Box>
          )}
          {batch?.status === 'error' && (
            <Box mt="50px">
              <Typography fontSize="30px" color={colors.accent} alignItems="center" display="flex" gap="10px">
                Cannot sort this batch. It contains orders with errors.
              </Typography>
            </Box>
          )}
          {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
        </Box>
        <Box flex="0.75">
          {batch?.orders.map((order) => (
            <Accordion key={order.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: getOrderColor(order) }}>
                <Box display="flex" gap="10px" width="100%" justifyContent="space-between" alignItems="center">
                  <Typography fontWeight="bold">Order {order.order_name}</Typography>
                  <Chip
                    label={`Tote Bin #${order.tote_bin_number}`}
                    sx={{ paddingInline: '10px', fontWeight: 'bold' }}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ width: '100%', backgroundColor: getOrderColor(order) }}>
                <Box
                  display="flex"
                  gap="5px"
                  width="100%"
                  padding="15px 20px"
                  sx={{
                    backgroundColor: colors.primary,
                    borderRadius: '10px',
                    textAlign: 'center',
                    '& p': {
                      fontWeight: 'bold',
                      fontSize: '16px',
                    },
                  }}
                >
                  <Typography flex="1">SKU</Typography>
                  <Typography flex="1">Qty Required</Typography>
                  <Typography flex="1">Qty Inserted</Typography>
                  <Typography flex="1">Qty Left</Typography>
                </Box>
                <Box display="flex" gap="5px" flexDirection="column" mt="10px">
                  {order.line_items.map((line_item, index) => (
                    <Box
                      key={line_item.id}
                      display="flex"
                      gap="5px"
                      width="100%"
                      padding="10px 20px"
                      sx={{
                        textAlign: 'center',
                        borderBottom: index === order.line_items.length - 1 ? undefined : '1px solid ' + colors.border,
                        backgroundColor: getRowColor(line_item),
                        borderRadius: '10px',
                      }}
                    >
                      <Typography flex="1">{line_item.sku}</Typography>
                      <Typography flex="1">{line_item.quantity}</Typography>
                      <Typography flex="1">{line_item.picked_count}</Typography>
                      <Typography flex="1">{line_item.quantity - line_item.picked_count}</Typography>
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
          {batch?.orders.length === 0 && batch?.status !== 'error' && (
            <Typography fontSize="30px" color={colors.accent} alignItems="center" display="flex" gap="10px">
              No orders in this batch.
            </Typography>
          )}
        </Box>
      </Box>

      <CustomModal
        header="Confirmation"
        open={openToteBinModal}
        setOpen={setOpenToteBinModal}
        placeAtTop
        onClose={closeModal}
      >
        {message}
        {!!selectedLineItem ? (
          <Box display="flex" gap="10px" justifyContent="center" mt="20px">
            <RegularButton
              label="No"
              size="large"
              onClick={() => {
                setOpenToteBinModal(false);
                closeModal();
              }}
              variant="outlined"
              color="error"
              styles={{ width: '150px' }}
            />
            <RegularButton
              label="Yes"
              size="large"
              onClick={handleUpdatePickedCount}
              color="success"
              styles={{ width: '150px' }}
            />
          </Box>
        ) : (
          <Box display="flex" gap="10px" justifyContent="center" mt="20px">
            <RegularButton
              label="Okay"
              onClick={() => {
                setOpenToteBinModal(false);
                closeModal();
              }}
              variant="outlined"
              color="info"
              styles={{ width: '150px' }}
            />
          </Box>
        )}
      </CustomModal>
    </Box>
  );
};

export default SortingStation;
